<template>
  <div class="wrapper">
    <el-result
      icon="info"
      title="添加域名解析记录"
    >
      <template #extra>
        <el-collapse
          v-model="activeName"
          accordion
          class="collapse"
        >
          <el-collapse-item
            title="Windows系统"
            name="1"
          >
            <ul class="collapseItem">
              <li>找到C:/System32/drivers/etc/hosts文件，如果没有创建该文件。</li>
              <li>
                增加一条DNS解析记录：
                <code>10.10.2.175 {{ from_host }}</code>
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item
            title="Linux系统"
            name="2"
          >
            <ul class="collapseItem">
              <li>找到/etc/hosts文件，如果没有创建该文件。</li>
              <li>
                增加一条DNS解析记录：
                <code>10.10.2.175 {{ from_host }}</code>
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item
            title="Mac系统"
            name="3"
          >
            <ul class="collapseItem">
              <li>找到/etc/hosts文件，如果没有创建该文件。</li>
              <li>
                增加一条DNS解析记录：
                <code>10.10.2.175 {{ from_host }}</code>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
        <el-button
          type="primary"
          size="medium"
          @click="goto"
        >已完成</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, computed } from 'vue';
function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
  return results == null ? "" : decodeURIComponent(results[1]);
}
function parseURL(url) {
  let a = document.createElement('a');
  a.href = url;
  let ret = {};
  // 判断是否有传递参数，若有，则转换成key-value对象形式
  if (a.search) {
    let seg = a.search.replace('?', '').split('&');
    for (let i = 0, len = seg.length; i < len; i++) {
      let key = seg[i].split('=')[0];
      let value = seg[i].split('=')[1];
      ret[key] = value;
    }
  }
  return {
    source: url, // 原URL值
    protocol: a.protocol.replace(':', ''), // 通信协议
    port: a.port, // 端口
    host: a.hostname, // 主机(域名或IP)
    path: a.pathname, // 路径
    query: a.search, // 传递参数
    hash: a.hash.replace('#', ''), // 哈希值
    param: ret // 传递参数key-value对象
  }
}
export default defineComponent({
  setup() {
    let _url = getParameterByName("from");
    let _host = parseURL(_url).host;
    return ({
      activeName: '1',
      from_url: _url,
      from_host: _host,
    })
  },
  methods: {
    goto() {
      window.location.href = this.from_url;
    }
  }
})
</script>

<style scoped>
  .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    justify-items: baseline;
    align-content: center;
    align-items: flex-start;
    align-self: stretch;
    justify-self: stretch;
    height: 100%;
  }
  .collapse {
    margin: 20px;
  }
  .collapse,
  .collapseItem {
    width: 500px;
    text-align: left;
  }
</style>