<template>
  <el-row>
    <el-col
      :xl="{span:12,offset:6}"
      :lg="{span:18, offset:3}"
      :md="{span:20, offset:2}"
      :sm="{span:24,offset:0}"
      :xs="{span:24, offset:0}"
      :span="24"
      class="cardWrapper"
    >
      <el-card
        class="card"
        v-for="(site, key) in getWorkspaceSites "
        :key="key"
        :body-style="{padding: '0px'}"
      >
        <el-image
          class="image"
          :src="site.img"
          fit="cover"
        ></el-image>
        <div class="cardMeta">
          <strong>{{ site.title }}</strong>
          <i>:&nbsp;</i>
          <span>{{ site.description }}</span>
        </div>
        <div class="actions">
          <time class="time">{{ site.description }}</time>
          <el-button
            type="text"
            class="button"
            @click="goto($event,site.url)"
          >访问</el-button>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, computed } from 'vue';

const sites = [
  { title: "Nexwiki", description: "团队知识库", category: "Workspace", url: "https://wikilab.mervin.me", img: "nexwiki.png" },
  { title: "NexFlow", description: "团队协作", category: "Workspace", url: "https://flowlab.mervin.me", img: "nexflow.png" },
  { title: "NexGit", description: "团队代码库管理", category: "Workspace", url: "https://gitlab.mervin.me", img: "nexgit.png" },
  { title: "NexDrive", description: "团队文件存储与管理", category: "Workspace", url: "https://drivelab.mervin.me", img: "nexdrive.png" },
  { title: "NexDraw", description: "在线流程图编辑", category: "Workspace", url: "https://drawlab.mervin.me", img: "nexdraw.png" },
  { title: "Cydar", description: "全球鹰网络基础安全态势感知", category: "Project", url: "https://cydar.cn/", img: "cydar.png" },
]
let sitesObj = sites.map((v, k) => {
  try {
    let img = v.img.replace(".png", "");
    const path = `../assets/screenshots/${img}.png`;
    const modules = import.meta.globEager("../assets/screenshots/*.png");
    v.img = modules[path].default;
  } catch (e) {
    console.log("not found", v.img)
  }
  return v
})
export default defineComponent({
  setup() {
    const sitesData = reactive(sitesObj);
    const getWorkspaceSites = computed(() => {
      return sitesData.filter((v, k) => {
        return v.category == "Workspace"
      })
    });
    return ({
      sitesData,
      getWorkspaceSites
    })

  },
  methods: {
    goto(evt, url) {
      window.location.href = url;
    }
  }
})
</script>

<style scoped>
  .cardWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-items: baseline;
    align-content: flex-start;
    align-items: flex-start;
  }
  .card {
    width: 250px;
    /* height: 200px; */
    margin: 10px;
  }
  .cardMeta {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    justify-items: baseline;
    align-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #e4e7ed;
    padding: 5px 10px;
  }
  .actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    justify-items: baseline;
    align-content: flex-start;
    align-items: center;
    margin: 5px 10px;
  }
  .actionBtn {
    padding: 5px;
    line-height: 16px;
    border: 1px solid #dcdfe6;
    background: #ebeef5;
    border-radius: 5px;
    margin-left: 5px;
  }
</style>