<template>
  <el-row>
    <el-col
      :xl="{span:12,offset:6}"
      :lg="{span:18, offset:3}"
      :md="{span:20, offset:2}"
      :sm="{span:24,offset:0}"
      :xs="{span:24, offset:0}"
      :span="24"
      class="navWrapper"
    >
      <h1 class="logoWrapper">
        <img
          :src="logoURL"
          alt="NexTeam project album"
        >
      </h1>
      <el-menu
        :default-active="activeIndex"
        class="navbar"
        router
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="/">
          NexHome
        </el-menu-item>
        <el-menu-item index="/workspace">
          Workspace
        </el-menu-item>
        <el-menu-item index="/project">
          Project
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, computed } from 'vue';
import logoRes from "../../assets/logo/nex+logo-v1.png";
// import logoRes from "../../assets/logo/nex+logo.svg";
export default defineComponent({
  setup() {
    const activeIndex = ref('/');
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath);
    };
    const logoURL = ref(logoRes);
    return {
      logoURL,
      activeIndex,
      handleSelect,
    };
  },
})
</script>

<style scoped>
  .navWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    justify-items: baseline;
    align-content: flex-start;
    align-items: center;
  }
  .logoWrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    height: 50px;
    margin: 0px;
    padding-right: 20px;
  }
  .logoWrapper img {
    display: inline-block;
    height: 40px;
  }
  .navbar a {
    text-decoration: none;
  }
</style>