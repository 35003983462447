<template>
  <el-container class="container">
    <el-header class="header">
      <appNav />
    </el-header>
    <el-main class="main">
      <!-- 路由匹配到的组件将渲染在这里 -->
      <router-view></router-view>
    </el-main>
    <el-footer class="footer">
      <el-row>
        <el-col
          :xl="{span:12,offset:6}"
          :lg="{span:18, offset:3}"
          :md="{span:20, offset:2}"
          :sm="{span:24,offset:0}"
          :xs="{span:24, offset:0}"
          :span="24"
          class="cardWrapper"
        >
          <p>
            Copyright © 物联网信息安全技术北京市重点实验室 2021
          </p>
        </el-col>
      </el-row>

    </el-footer>
  </el-container>
</template>

<script>
import Nav from './components/head/Nav.vue'
import { defineComponent, reactive, toRefs, ref, computed } from 'vue';

import favicon from './assets/logo/faviconx16.png'

export default defineComponent({
  setup() {
    const activeIndex = ref('2');
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath);
    };
    return {
      activeIndex,
      handleSelect,
    };
  },
  components: {
    appNav: Nav,
  },
  created() {
    let link = document.createElement("link");
    link.setAttribute("rel", "icon");
    link.setAttribute("href", favicon);
    let heads = document.getElementsByTagName("head");
    if (heads.length)
      heads[0].appendChild(link);
    else
      doc.documentElement.appendChild(link);

  },

});
</script>

<style>
  body {
    margin: 0px;
    padding: 0px;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0px;
    padding: 0px;
  }
  .element-plus-logo {
    width: 50%;
  }
</style>

<style scoped>
  .container {
    height: 100vh;
    width: 100vw;
  }
  .header {
    border-bottom: 1px solid #dcdfe6;
    /* height: 61px; */
  }

  .main {
    max-height: calc(100vh - 60px -100px);
    overflow-y: scroll;
  }

  .footer {
    border-top: 1px solid #dcdfe6;
    /* height: 100px; */
    padding: 10px 0px;
  }
</style>