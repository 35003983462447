<template>
  <div class="wrapper">
    <el-empty :image-size="200"></el-empty>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, computed } from 'vue';

export default defineComponent({
  setup() {
    return ({
    })
  },
  methods: {
  }
})
</script>

<style scoped>
</style>