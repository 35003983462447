import { createRouter, createWebHistory } from 'vue-router'
const modules = import.meta.globEager("../components/*.vue")
const getComponent = (name) => {
    try {
        const path = `../components/${name}.vue`;
        return modules[path].default;
    } catch (e) {
        console.log("not found", name)
    }
}
const routes = [
    {
        path: "/",
        name: "Home",
        component: getComponent("WorkspaceAlbum"),
    },
    {
        path: "/workspace",
        name: "Workspace",
        component: getComponent("WorkspaceAlbum"),
        meta: { transition: 'slide-left' },
    },
    {
        path: "/project",
        name: "Project",
        component: getComponent("ProjectAlbum"),
    },
    {
        path: "/dns",
        name: "DNS",
        component: getComponent("DNSInfo"),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: getComponent("NotFound"),
    },
]

export default createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes,
});