import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
// import 'vite/dynamic-import-polyfill'

import router from './router'

console.log(import.meta.env.VITE_APP_TITLE)

const app = createApp(App);
app.use(ElementPlus);
app.use(router);
app.mount('#app')
